import { isSSR } from "./ssr";

// As remix rebuilds everything in development, caching doesn't work, so this is a helper for that.
export function persistentDevCache<T>(name: string, builder: () => T): T {
  if (process.env.NODE_ENV === "production") {
    return builder();
  }
  // @ts-ignore
  if (global[name] === undefined) {
    // @ts-ignore
    global[name] = builder();
  }
  // @ts-ignore
  return global[name];
}
