import { buildUrl, get } from "./fetchers";

const API = "https://api.coingecko.com/";

export interface TokenPrices {
  [token: string]: { [currency: string]: number };
}

export async function fetchCoingeckoPrices(
  tokens: string[]
): Promise<TokenPrices> {
  try {
    return await get(
      buildUrl(API, `api/v3/simple/price`, {
        ids: tokens.join(","),
        vs_currencies: "usd",
        include_24hr_change: true
      })
    );
  } catch {
    return {};
  }
}

